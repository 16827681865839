/**
 * File Name: css-helpers.css
 */

@import "_variables";
@import "helpers-placeholders";


/*  [ 01 ] - Colors
===================== */

/* Opacity Values */
.opacity-05 { @extend %opacity-05; }
.opacity-10 { @extend %opacity-10; }
.opacity-15 { @extend %opacity-15; }
.opacity-20 { @extend %opacity-20; }
.opacity-25 { @extend %opacity-25; }
.opacity-30 { @extend %opacity-30; }
.opacity-35 { @extend %opacity-35; }
.opacity-40 { @extend %opacity-40; }
.opacity-45 { @extend %opacity-45; }
.opacity-50 { @extend %opacity-50; }
.opacity-55 { @extend %opacity-55; }
.opacity-60 { @extend %opacity-60; }
.opacity-65 { @extend %opacity-65; }
.opacity-70 { @extend %opacity-70; }
.opacity-75 { @extend %opacity-75; }
.opacity-80 { @extend %opacity-80; }
.opacity-85 { @extend %opacity-85; }
.opacity-90 { @extend %opacity-90; }
.opacity-95 { @extend %opacity-95; }
.opacity-100 { @extend %opacity-100; }

/* Colors */
.color-theme { color: $theme-color-1; }
.color-bg-theme { background-color: $theme-color-1; }
.color-heading { color: $heading-color; }
.color-bg-heading { background-color: $heading-color; }
.color-dark { color: $dark-color; }
.color-bg-dark { background-color: $dark-color; }
.color-white { color: #fff; }
.color-bg-white { background-color: #fff; }
.color-grey { color: #666; }
.color-bg-grey { background-color: #666; }
.color-red { color: $color-red; }
.color-green { color: $color-green; }
.color-bg-gradient-1 { @include bgGradient($theme-color-1, darken($theme-color-2, 15%), 270deg); }
.color-bg-gradient-2 { @include bgGradient(#2ebf6d, darken(#5273f9, 15%), 45deg); }

/* Font Sizes */
.font-size {
	&-10 { font-size: 10px; }
	&-11 { font-size: 11px; }
	&-12 { font-size: 12px; }
	&-13 { font-size: 13px; }
	&-14 { font-size: 14px; }
	&-15 { font-size: 15px; }
	&-16 { font-size: 16px; }
	&-17 { font-size: 17px; }
	&-18 { font-size: 18px; }
	&-19 { font-size: 19px; }
	&-20 { font-size: 20px; }
	&-22 { 
		font-size: 22px;

		@media (max-width: 1400px) {
		  font-size: 18px;
		} // < 1400px width

		@media (max-width: 480px) {
		  font-size: 14px;
		} // < 480px width
	}
}

/* Text Alignment */
@media (max-width: 992px) {
	.md-text-center {
		text-align: center;
	}

	.md-text-right {
		text-align: right;
	}

	.md-text-left {
		text-align: left;
	}
} // < 992px width

@media (max-width: 768px) {
	.sm-text-center {
		text-align: center;
	}

	.sm-text-right {
		text-align: right;
	}

	.sm-text-left {
		text-align: left;
	}
} // < 768px width
	
@media (max-width: 480px) {
	.xs-text-center {
		text-align: center;
	}

	.xs-text-right {
		text-align: right;
	}

	.xs-text-left {
		text-align: left;
	}
} // < 480px width

@media (max-width: 320px) {
	.md-text-center {
		text-align: center;
	}

	.md-text-right {
		text-align: right;
	}

	.md-text-left {
		text-align: left;
	}
} // < 320px width




/*  [ 02 ] - Text Styles
========================== */

.font-weight-100 { font-weight: 100; }
.font-weight-200 { font-weight: 200; }
.font-weight-300 { font-weight: 300; }
.font-weight-400 { font-weight: 400; }
.font-weight-500 { font-weight: 500; }
.font-weight-600 { font-weight: 600; }
.font-weight-700 { font-weight: 700; }
.font-weight-800 { font-weight: 800; }
.font-weight-900 { font-weight: 900; }


/*  [ 02 ] - Padding
====================== */

/* Top Padding */
.pt-0 { padding-top: 0; }
.pt-10 { padding-top: 10px; }
.pt-20 { padding-top: 20px; }
.pt-30 { padding-top: 30px; }
.pt-40 { padding-top: 40px; }
.pt-50 { padding-top: 50px; }
.pt-60 { padding-top: 60px; }
.pt-70 { padding-top: 70px; }
.pt-80 { padding-top: 80px; }
.pt-90 { padding-top: 90px; }
.pt-100 { padding-top: 100px; }
.pt-110 { padding-top: 110px; }
.pt-120 { padding-top: 120px; }
.pt-130 { padding-top: 130px; }
.pt-140 { padding-top: 140px; }

/* Bottom Padding */
.pb-0 { padding-bottom: 0; }
.pb-10 { padding-bottom: 10px; }
.pb-20 { padding-bottom: 20px; }
.pb-30 { padding-bottom: 30px; }
.pb-40 { padding-bottom: 40px; }
.pb-50 { padding-bottom: 50px; }
.pb-60 { padding-bottom: 60px; }
.pb-70 { padding-bottom: 70px; }
.pb-80 { padding-bottom: 80px; }
.pb-90 { padding-bottom: 90px; }
.pb-100 { padding-bottom: 100px; }
.pb-110 { padding-bottom: 110px; }
.pb-120 { padding-bottom: 120px; }
.pb-130 { padding-bottom: 130px; }
.pb-140 { padding-bottom: 140px; }

/* Left Padding */
.pl-0 { padding-left: 0; }
.pl-10 { padding-left: 10px; }
.pl-20 { padding-left: 20px; }
.pl-30 { padding-left: 30px; }
.pl-40 { padding-left: 40px; }
.pl-50 { padding-left: 50px; }
.pl-60 { padding-left: 60px; }
.pl-70 { padding-left: 70px; }
.pl-80 { padding-left: 80px; }
.pl-90 { padding-left: 90px; }
.pl-100 { padding-left: 100px; }
.pl-110 { padding-left: 110px; }
.pl-120 { padding-left: 120px; }
.pl-130 { padding-left: 130px; }
.pl-140 { padding-left: 140px; }

/* Right Padding */
.pr-0 { padding-right: 0; }
.pr-10 { padding-right: 10px; }
.pr-20 { padding-right: 20px; }
.pr-30 { padding-right: 30px; }
.pr-40 { padding-right: 40px; }
.pr-50 { padding-right: 50px; }
.pr-60 { padding-right: 60px; }
.pr-70 { padding-right: 70px; }
.pr-80 { padding-right: 80px; }
.pr-90 { padding-right: 90px; }
.pr-100 { padding-right: 100px; }
.pr-110 { padding-right: 110px; }
.pr-120 { padding-right: 120px; }
.pr-130 { padding-right: 130px; }
.pr-140 { padding-right: 140px; }

/* Top Bottom Padding */
.ptb-0 { padding: 0; }
.ptb-10 { padding: 10px 0; }
.ptb-20 { padding: 20px 0; }
.ptb-30 { padding: 30px 0; }
.ptb-40 { padding: 40px 0; }
.ptb-50 { padding: 50px 0; }
.ptb-60 { padding: 60px 0; }
.ptb-70 { padding: 70px 0; }
.ptb-80 { padding: 80px 0; }
.ptb-90 { padding: 90px 0; }
.ptb-100 { padding: 100px 0; }
.ptb-110 { padding: 110px 0; }
.ptb-120 { padding: 120px 0; }
.ptb-130 { padding: 130px 0; }
.ptb-140 { padding: 140px 0; }

/* Left Right Padding */
.plr-0 { padding: 0; }
.plr-10 { padding: 0 10px; }
.plr-20 { padding: 0 20px; }
.plr-30 { padding: 0 30px; }
.plr-40 { padding: 0 40px; }
.plr-50 { padding: 0 50px; }
.plr-60 { padding: 0 60px; }
.plr-70 { padding: 0 70px; }
.plr-80 { padding: 0 80px; }
.plr-90 { padding: 0 90px; }
.plr-100 { padding: 0 100px; }
.plr-110 { padding: 0 110px; }
.plr-120 { padding: 0 120px; }
.plr-130 { padding: 0 130px; }
.plr-140 { padding: 0 140px; }


/* Responsive Padding [ col-md- ] */
@media (max-width: 992px) {
	/* Responsive Top Padding */
	.pt-md-0 { padding-top: 0; }
	.pt-md-10 { padding-top: 10px; }
	.pt-md-20 { padding-top: 20px; }
	.pt-md-30 { padding-top: 30px; }
	.pt-md-40 { padding-top: 40px; }
	.pt-md-50 { padding-top: 50px; }
	.pt-md-60 { padding-top: 60px; }
	.pt-md-70 { padding-top: 70px; }
	.pt-md-80 { padding-top: 80px; }
	.pt-md-90 { padding-top: 90px; }
	.pt-md-100 { padding-top: 100px; }
	.pt-md-110 { padding-top: 110px; }
	.pt-md-120 { padding-top: 120px; }
	.pt-md-130 { padding-top: 130px; }
	.pt-md-140 { padding-top: 140px; }

	/* Responsive Bottom Padding */
	.pb-md-0 { padding-bottom: 0; }
	.pb-md-10 { padding-bottom: 10px; }
	.pb-md-20 { padding-bottom: 20px; }
	.pb-md-30 { padding-bottom: 30px; }
	.pb-md-40 { padding-bottom: 40px; }
	.pb-md-50 { padding-bottom: 50px; }
	.pb-md-60 { padding-bottom: 60px; }
	.pb-md-70 { padding-bottom: 70px; }
	.pb-md-80 { padding-bottom: 80px; }
	.pb-md-90 { padding-bottom: 90px; }
	.pb-md-100 { padding-bottom: 100px; }
	.pb-md-110 { padding-bottom: 110px; }
	.pb-md-120 { padding-bottom: 120px; }
	.pb-md-130 { padding-bottom: 130px; }
	.pb-md-140 { padding-bottom: 140px; }

	/* Responsive Left Padding */
	.pl-md-0 { padding-left: 0; }
	.pl-md-10 { padding-left: 10px; }
	.pl-md-20 { padding-left: 20px; }
	.pl-md-30 { padding-left: 30px; }
	.pl-md-40 { padding-left: 40px; }
	.pl-md-50 { padding-left: 50px; }
	.pl-md-60 { padding-left: 60px; }
	.pl-md-70 { padding-left: 70px; }
	.pl-md-80 { padding-left: 80px; }
	.pl-md-90 { padding-left: 90px; }
	.pl-md-100 { padding-left: 100px; }
	.pl-md-110 { padding-left: 110px; }
	.pl-md-120 { padding-left: 120px; }
	.pl-md-130 { padding-left: 130px; }
	.pl-md-140 { padding-left: 140px; }

	/* Responsive Right Padding */
	.pr-md-0 { padding-right: 0; }
	.pr-md-10 { padding-right: 10px; }
	.pr-md-20 { padding-right: 20px; }
	.pr-md-30 { padding-right: 30px; }
	.pr-md-40 { padding-right: 40px; }
	.pr-md-50 { padding-right: 50px; }
	.pr-md-60 { padding-right: 60px; }
	.pr-md-70 { padding-right: 70px; }
	.pr-md-80 { padding-right: 80px; }
	.pr-md-90 { padding-right: 90px; }
	.pr-md-100 { padding-right: 100px; }
	.pr-md-110 { padding-right: 110px; }
	.pr-md-120 { padding-right: 120px; }
	.pr-md-130 { padding-right: 130px; }
	.pr-md-140 { padding-right: 140px; }

	/* Responsive Top Bottom Padding */
	.ptb-md-0 { padding: 0; }
	.ptb-md-10 { padding: 10px 0; }
	.ptb-md-20 { padding: 20px 0; }
	.ptb-md-30 { padding: 30px 0; }
	.ptb-md-40 { padding: 40px 0; }
	.ptb-md-50 { padding: 50px 0; }
	.ptb-md-60 { padding: 60px 0; }
	.ptb-md-70 { padding: 70px 0; }
	.ptb-md-80 { padding: 80px 0; }
	.ptb-md-90 { padding: 90px 0; }
	.ptb-md-100 { padding: 100px 0; }
	.ptb-md-110 { padding: 110px 0; }
	.ptb-md-120 { padding: 120px 0; }
	.ptb-md-130 { padding: 130px 0; }
	.ptb-md-140 { padding: 140px 0; }

	/* Responsive Left Right Padding */
	.plr-md-0 { padding: 0; }
	.plr-md-10 { padding: 0 10px; }
	.plr-md-20 { padding: 0 20px; }
	.plr-md-30 { padding: 0 30px; }
	.plr-md-40 { padding: 0 40px; }
	.plr-md-50 { padding: 0 50px; }
	.plr-md-60 { padding: 0 60px; }
	.plr-md-70 { padding: 0 70px; }
	.plr-md-80 { padding: 0 80px; }
	.plr-md-90 { padding: 0 90px; }
	.plr-md-100 { padding: 0 100px; }
	.plr-md-110 { padding: 0 110px; }
	.plr-md-120 { padding: 0 120px; }
	.plr-md-130 { padding: 0 130px; }
	.plr-md-140 { padding: 0 140px; }
}


/*  [ 03 ] - Margin
===================== */

/* Top Margin */
.mt-0 { margin-top: 0; }
.mt-10 { margin-top: 10px; }
.mt-20 { margin-top: 20px; }
.mt-30 { margin-top: 30px; }
.mt-40 { margin-top: 40px; }
.mt-50 { margin-top: 50px; }
.mt-60 { margin-top: 60px; }
.mt-70 { margin-top: 70px; }
.mt-80 { margin-top: 80px; }
.mt-90 { margin-top: 90px; }
.mt-100 { margin-top: 100px; }
.mt-110 { margin-top: 110px; }
.mt-120 { margin-top: 120px; }
.mt-130 { margin-top: 130px; }
.mt-140 { margin-top: 140px; }

/* Bottom Margin */
.mb-0 { margin-bottom: 0; }
.mb-10 { margin-bottom: 10px; }
.mb-20 { margin-bottom: 20px; }
.mb-30 { margin-bottom: 30px; }
.mb-40 { margin-bottom: 40px; }
.mb-50 { margin-bottom: 50px; }
.mb-60 { margin-bottom: 60px; }
.mb-70 { margin-bottom: 70px; }
.mb-80 { margin-bottom: 80px; }
.mb-90 { margin-bottom: 90px; }
.mb-100 { margin-bottom: 100px; }
.mb-110 { margin-bottom: 110px; }
.mb-120 { margin-bottom: 120px; }
.mb-130 { margin-bottom: 130px; }
.mb-140 { margin-bottom: 140px; }

/* Left Margin */
.ml-0 { margin-left: 0; }
.ml-10 { margin-left: 10px; }
.ml-20 { margin-left: 20px; }
.ml-30 { margin-left: 30px; }
.ml-40 { margin-left: 40px; }
.ml-50 { margin-left: 50px; }
.ml-60 { margin-left: 60px; }
.ml-70 { margin-left: 70px; }
.ml-80 { margin-left: 80px; }
.ml-90 { margin-left: 90px; }
.ml-100 { margin-left: 100px; }
.ml-110 { margin-left: 110px; }
.ml-120 { margin-left: 120px; }
.ml-130 { margin-left: 130px; }
.ml-140 { margin-left: 140px; }

/* Right Margin */
.mr-0 { margin-right: 0; }
.mr-10 { margin-right: 10px; }
.mr-20 { margin-right: 20px; }
.mr-30 { margin-right: 30px; }
.mr-40 { margin-right: 40px; }
.mr-50 { margin-right: 50px; }
.mr-60 { margin-right: 60px; }
.mr-70 { margin-right: 70px; }
.mr-80 { margin-right: 80px; }
.mr-90 { margin-right: 90px; }
.mr-100 { margin-right: 100px; }
.mr-110 { margin-right: 110px; }
.mr-120 { margin-right: 120px; }
.mr-130 { margin-right: 130px; }
.mr-140 { margin-right: 140px; }

/* Top Bottom Margin */
.mtb-0 { margin: 0; }
.mtb-10 { margin: 10px 0; }
.mtb-20 { margin: 20px 0; }
.mtb-30 { margin: 30px 0; }
.mtb-40 { margin: 40px 0; }
.mtb-50 { margin: 50px 0; }
.mtb-60 { margin: 60px 0; }
.mtb-70 { margin: 70px 0; }
.mtb-80 { margin: 80px 0; }
.mtb-90 { margin: 90px 0; }
.mtb-100 { margin: 100px 0; }
.mtb-110 { margin: 110px 0; }
.mtb-120 { margin: 120px 0; }
.mtb-130 { margin: 130px 0; }
.mtb-140 { margin: 140px 0; }

/* Left Right Margin */
.mlr-0 { margin: 0; }
.mlr-10 { margin: 0 10px; }
.mlr-20 { margin: 0 20px; }
.mlr-30 { margin: 0 30px; }
.mlr-40 { margin: 0 40px; }
.mlr-50 { margin: 0 50px; }
.mlr-60 { margin: 0 60px; }
.mlr-70 { margin: 0 70px; }
.mlr-80 { margin: 0 80px; }
.mlr-90 { margin: 0 90px; }
.mlr-100 { margin: 0 100px; }
.mlr-110 { margin: 0 110px; }
.mlr-120 { margin: 0 120px; }
.mlr-130 { margin: 0 130px; }
.mlr-140 { margin: 0 140px; }

/* Responsive Margin [ That added for small devices < 992px ] */
@media (max-width: 992px) {

	/* Responsive Top Margin */
	.mt-md-0 { margin-top: 0; }
	.mt-md-10 { margin-top: 10px; }
	.mt-md-20 { margin-top: 20px; }
	.mt-md-30 { margin-top: 30px; }
	.mt-md-40 { margin-top: 40px; }
	.mt-md-50 { margin-top: 50px; }
	.mt-md-60 { margin-top: 60px; }
	.mt-md-70 { margin-top: 70px; }
	.mt-md-80 { margin-top: 80px; }
	.mt-md-90 { margin-top: 90px; }
	.mt-md-100 { margin-top: 100px; }
	.mt-md-110 { margin-top: 110px; }
	.mt-md-120 { margin-top: 120px; }
	.mt-md-130 { margin-top: 130px; }
	.mt-md-140 { margin-top: 140px; }

	/* Responsive Bottom Margin */
	.mb-md-0 { margin-bottom: 0; }
	.mb-md-10 { margin-bottom: 10px; }
	.mb-md-20 { margin-bottom: 20px; }
	.mb-md-30 { margin-bottom: 30px; }
	.mb-md-40 { margin-bottom: 40px; }
	.mb-md-50 { margin-bottom: 50px; }
	.mb-md-60 { margin-bottom: 60px; }
	.mb-md-70 { margin-bottom: 70px; }
	.mb-md-80 { margin-bottom: 80px; }
	.mb-md-90 { margin-bottom: 90px; }
	.mb-md-100 { margin-bottom: 100px; }
	.mb-md-110 { margin-bottom: 110px; }
	.mb-md-120 { margin-bottom: 120px; }
	.mb-md-130 { margin-bottom: 130px; }
	.mb-md-140 { margin-bottom: 140px; }

	/* Responsive Left Margin */
	.ml-md-0 { margin-left: 0; }
	.ml-md-10 { margin-left: 10px; }
	.ml-md-20 { margin-left: 20px; }
	.ml-md-30 { margin-left: 30px; }
	.ml-md-40 { margin-left: 40px; }
	.ml-md-50 { margin-left: 50px; }
	.ml-md-60 { margin-left: 60px; }
	.ml-md-70 { margin-left: 70px; }
	.ml-md-80 { margin-left: 80px; }
	.ml-md-90 { margin-left: 90px; }
	.ml-md-100 { margin-left: 100px; }
	.ml-md-110 { margin-left: 110px; }
	.ml-md-120 { margin-left: 120px; }
	.ml-md-130 { margin-left: 130px; }
	.ml-md-140 { margin-left: 140px; }

	/* Responsive Right Margin */
	.mr-md-0 { margin-right: 0; }
	.mr-md-10 { margin-right: 10px; }
	.mr-md-20 { margin-right: 20px; }
	.mr-md-30 { margin-right: 30px; }
	.mr-md-40 { margin-right: 40px; }
	.mr-md-50 { margin-right: 50px; }
	.mr-md-60 { margin-right: 60px; }
	.mr-md-70 { margin-right: 70px; }
	.mr-md-80 { margin-right: 80px; }
	.mr-md-90 { margin-right: 90px; }
	.mr-md-100 { margin-right: 100px; }
	.mr-md-110 { margin-right: 110px; }
	.mr-md-120 { margin-right: 120px; }
	.mr-md-130 { margin-right: 130px; }
	.mr-md-140 { margin-right: 140px; }

	/* Responsive Top Bottom Margin */
	.mtb-md-0 { margin: 0; }
	.mtb-md-10 { margin: 10px 0; }
	.mtb-md-20 { margin: 20px 0; }
	.mtb-md-30 { margin: 30px 0; }
	.mtb-md-40 { margin: 40px 0; }
	.mtb-md-50 { margin: 50px 0; }
	.mtb-md-60 { margin: 60px 0; }
	.mtb-md-70 { margin: 70px 0; }
	.mtb-md-80 { margin: 80px 0; }
	.mtb-md-90 { margin: 90px 0; }
	.mtb-md-100 { margin: 100px 0; }
	.mtb-md-110 { margin: 110px 0; }
	.mtb-md-120 { margin: 120px 0; }
	.mtb-md-130 { margin: 130px 0; }
	.mtb-md-140 { margin: 140px 0; }

	/* Responsive Left Right Margin */
	.mlr-md-0 { margin: 0; }
	.mlr-md-10 { margin: 0 10px; }
	.mlr-md-20 { margin: 0 20px; }
	.mlr-md-30 { margin: 0 30px; }
	.mlr-md-40 { margin: 0 40px; }
	.mlr-md-50 { margin: 0 50px; }
	.mlr-md-60 { margin: 0 60px; }
	.mlr-md-70 { margin: 0 70px; }
	.mlr-md-80 { margin: 0 80px; }
	.mlr-md-90 { margin: 0 90px; }
	.mlr-md-100 { margin: 0 100px; }
	.mlr-md-110 { margin: 0 110px; }
	.mlr-md-120 { margin: 0 120px; }
	.mlr-md-130 { margin: 0 130px; }
	.mlr-md-140 { margin: 0 140px; }

}

/* Responsive Margin [ That added for small devices < 768px ] */
@media (max-width: 768px) {

	/* Responsive Bottom Margin */
	.mb-sm-0 { margin-bottom: 0; }
	.mb-sm-10 { margin-bottom: 10px; }
	.mb-sm-20 { margin-bottom: 20px; }
	.mb-sm-30 { margin-bottom: 30px; }
	.mb-sm-40 { margin-bottom: 40px; }
	.mb-sm-50 { margin-bottom: 50px; }
	.mb-sm-60 { margin-bottom: 60px; }
	.mb-sm-70 { margin-bottom: 70px; }
	.mb-sm-80 { margin-bottom: 80px; }
	.mb-sm-90 { margin-bottom: 90px; }
	.mb-sm-100 { margin-bottom: 100px; }
	.mb-sm-110 { margin-bottom: 110px; }
	.mb-sm-120 { margin-bottom: 120px; }
	.mb-sm-130 { margin-bottom: 130px; }
	.mb-sm-140 { margin-bottom: 140px; }

	/* Responsive Top Margin */
	.mt-sm-0 { margin-top: 0; }
	.mt-sm-10 { margin-top: 10px; }
	.mt-sm-20 { margin-top: 20px; }
	.mt-sm-30 { margin-top: 30px; }
	.mt-sm-40 { margin-top: 40px; }
	.mt-sm-50 { margin-top: 50px; }
	.mt-sm-60 { margin-top: 60px; }
	.mt-sm-70 { margin-top: 70px; }
	.mt-sm-80 { margin-top: 80px; }
	.mt-sm-90 { margin-top: 90px; }
	.mt-sm-100 { margin-top: 100px; }
	.mt-sm-110 { margin-top: 110px; }
	.mt-sm-120 { margin-top: 120px; }
	.mt-sm-130 { margin-top: 130px; }
	.mt-sm-140 { margin-top: 140px; }
}

/* Responsive Margin [ That added for small devices < 480px ] */
@media (max-width: 480px) {

	/* Responsive Bottom Margin */
	.mb-xs-0 { margin-bottom: 0; }
	.mb-xs-10 { margin-bottom: 10px; }
	.mb-xs-20 { margin-bottom: 20px; }
	.mb-xs-30 { margin-bottom: 30px; }
	.mb-xs-40 { margin-bottom: 40px; }
	.mb-xs-50 { margin-bottom: 50px; }
	.mb-xs-60 { margin-bottom: 60px; }
	.mb-xs-70 { margin-bottom: 70px; }
	.mb-xs-80 { margin-bottom: 80px; }
	.mb-xs-90 { margin-bottom: 90px; }
	.mb-xs-100 { margin-bottom: 100px; }
	.mb-xs-110 { margin-bottom: 110px; }
	.mb-xs-120 { margin-bottom: 120px; }
	.mb-xs-130 { margin-bottom: 130px; }
	.mb-xs-140 { margin-bottom: 140px; }

	/* Responsive Top Margin */
	.mt-xs-0 { margin-top: 0; }
	.mt-xs-10 { margin-top: 10px; }
	.mt-xs-20 { margin-top: 20px; }
	.mt-xs-30 { margin-top: 30px; }
	.mt-xs-40 { margin-top: 40px; }
	.mt-xs-50 { margin-top: 50px; }
	.mt-xs-60 { margin-top: 60px; }
	.mt-xs-70 { margin-top: 70px; }
	.mt-xs-80 { margin-top: 80px; }
	.mt-xs-90 { margin-top: 90px; }
	.mt-xs-100 { margin-top: 100px; }
	.mt-xs-110 { margin-top: 110px; }
	.mt-xs-120 { margin-top: 120px; }
	.mt-xs-130 { margin-top: 130px; }
	.mt-xs-140 { margin-top: 140px; }
}


/*  [ 03 ] - Heights
====================== */

.h-0 { height: 0px; }
.h-10 { height: 10px; }
.h-20 { height: 20px; }
.h-30 { height: 30px; }
.h-40 { height: 40px; }
.h-50 { height: 50px; }
.h-60 { height: 60px; }
.h-70 { height: 70px; }
.h-80 { height: 80px; }
.h-90 { height: 90px; }
.h-100 { height: 100px; }
.h-120 { height: 120px; }
.h-140 { height: 140px; }
.h-160 { height: 160px; }
.h-180 { height: 180px; }
.h-200 { height: 200px; }
.h-220 { height: 220px; }
.h-240 { height: 240px; }
.h-260 { height: 260px; }
.h-280 { height: 280px; }
.h-300 { height: 300px; }
.h-320 { height: 320px; }
.h-340 { height: 340px; }
.h-360 { height: 360px; }
.h-380 { height: 380px; }
.h-400 { height: 400px; }
.h-420 { height: 420px; }
.h-440 { height: 440px; }
.h-460 { height: 460px; }
.h-480 { height: 480px; }
.h-500 { height: 500px; }
.h-520 { height: 520px; }
.h-540 { height: 540px; }
.h-560 { height: 560px; }
.h-580 { height: 580px; }
.h-600 { height: 600px; }
.h-620 { height: 620px; }
.h-640 { height: 640px; }
.h-660 { height: 660px; }
.h-680 { height: 680px; }
.h-700 { height: 700px; }
.h-720 { height: 720px; }
.h-740 { height: 740px; }
.h-760 { height: 760px; }
.h-780 { height: 780px; }
.h-800 { height: 800px; }
.h-820 { height: 820px; }
.h-840 { height: 840px; }
.h-860 { height: 860px; }
.h-880 { height: 880px; }
.h-900 { height: 900px; }

@media (max-width: 992px) {
	.h-md-0 { height: 0px; }
	.h-md-10 { height: 10px; }
	.h-md-20 { height: 20px; }
	.h-md-30 { height: 30px; }
	.h-md-40 { height: 40px; }
	.h-md-50 { height: 50px; }
	.h-md-60 { height: 60px; }
	.h-md-70 { height: 70px; }
	.h-md-80 { height: 80px; }
	.h-md-90 { height: 90px; }
	.h-md-100 { height: 100px; }
	.h-md-120 { height: 120px; }
	.h-md-140 { height: 140px; }
	.h-md-160 { height: 160px; }
	.h-md-180 { height: 180px; }
	.h-md-200 { height: 200px; }
	.h-md-220 { height: 220px; }
	.h-md-240 { height: 240px; }
	.h-md-260 { height: 260px; }
	.h-md-280 { height: 280px; }
	.h-md-300 { height: 300px; }
	.h-md-320 { height: 320px; }
	.h-md-340 { height: 340px; }
	.h-md-360 { height: 360px; }
	.h-md-380 { height: 380px; }
	.h-md-400 { height: 400px; }
	.h-md-420 { height: 420px; }
	.h-md-440 { height: 440px; }
	.h-md-460 { height: 460px; }
	.h-md-480 { height: 480px; }
	.h-md-500 { height: 500px; }
	.h-md-520 { height: 520px; }
	.h-md-540 { height: 540px; }
	.h-md-560 { height: 560px; }
	.h-md-580 { height: 580px; }
	.h-md-600 { height: 600px; }
	.h-md-620 { height: 620px; }
	.h-md-640 { height: 640px; }
	.h-md-660 { height: 660px; }
	.h-md-680 { height: 680px; }
	.h-md-700 { height: 700px; }
	.h-md-720 { height: 720px; }
	.h-md-740 { height: 740px; }
	.h-md-760 { height: 760px; }
	.h-md-780 { height: 780px; }
	.h-md-800 { height: 800px; }
	.h-md-820 { height: 820px; }
	.h-md-840 { height: 840px; }
	.h-md-860 { height: 860px; }
	.h-md-880 { height: 880px; }
	.h-md-900 { height: 900px; }
}


/*  [ 04 ] - Width
==================== */

.w-100p {
	width: 100%;
}


/*  [ 05 ] - Hidden Elements
============================== */

@media (max-width: 480px) {
  .hidden-u-480 {
	  display: none;
  }
} // < 480px width

@media (min-width: 480px) {
	.hidden-a-480 {
		display: none;
	}
} // > 480px width



// Imports ( Rest imports )
// ==================================



// ==================================
