// =====================================
//  footer.scss
//   |
//   |-> Footer
// =====================================


@import "_variables";


/**
 *  -------------------------------------------------------------------------------
 *  ( 05 ) - Footer
 *  -------------------------------------------------------------------------------
 *
 *  This part contains footer and mini footer
 *
 *  ------
 *
 *  It has the following code:
 *
 *    |
 *    |-->  [ 01 ] - Footer
 *    |-->  [ 02 ] - Footer Bars
 
 */




/*  [ 01 ] - Footer
===================== */

#footer {
  overflow: hidden;
  position: relative;
  z-index: 1;
}


/*  [ 02 ] - Footer Bars
========================== */

/* Footer Bar */
.footer-bar {
  padding: 100px 0;
  background: transparent;

  & + .footer-bar {
    // border-top: 1px solid $theme-color-4;
  }

  .fb-row {
    // display: flex;
    // align-items: flex-start;

    & + .fb-row {
      padding-top: 50px;
      margin-top: 50px;
      border-top: 1px solid rgba($dark-color,0.05);
    }
  }
}

@media (max-width: 768px) {
  .footer-bar {
    .fb-row {
      flex-direction: column;
    }
  }
} // < 768px width


.footer-bar-wrap {

}

.footer-bar {
  .container,
  .row,
  [class*="col-"] {
    // height: 100%;
  }
}

/* Footer Bar 1 */
#footer-bar-1 {
  padding: 0;

  .footer-bar-wrap {
    width: 100%;
  }

  &.footer-bar {
    .fb-row {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 20px 0;

      @media (max-width: 768px) {
        flex-direction: column;
        justify-content: center;
        padding: 30px 0;
      } // < 768px width
    }
  }

  .social-icons {
    margin: 0 0 -5px auto;

    @media (max-width: 768px) {
      margin-left: 0;
      margin-top: 10px;
    } // < 768px width
  }
}


/* Copyrights Message */
.copyrights-message {
  font-size: 16px;
  font-weight: 500;

  > * {
    opacity: 0.7;
  }

  a {
    opacity: 1;
    font-weight: 700;
    // color: #fff;
    
    &:hover {
      text-decoration: underline;
    }
  }

  .text-white & {
    color: #fff;
  }
}


  

// Imports ( Rest imports )
// ==================================

@import "helpers-placeholders";

// ==================================
