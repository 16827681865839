// This option must meet the default theme color

@import "_variables";

/**
 * File Name: style-switcher.css
 */

.style-switcher {
  // display: none !important;
  direction: ltr !important;
  width: 320px;
  height: 100%;
  text-align: center;
  background: #fff;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  font-family: "Open Sans", sans-serif;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 21;
    	  transition: all 0.8s cubic-bezier(0.19, 1, 0.22, 1);
  -webkit-transform: translateX(100%);
      	  transform: translateX(100%);

  .btn.purchase-btn {
    font-size: 16px;
  	background: #82b440;
  	border-color: #82b440;
    box-shadow: none;
  }

  .btn.dark,
  .btn.hover-dark:hover {
    background: #111;
    border-color: #111;
    box-shadow: none;
  }

  /* Scroll Settings */
  .ss-wrapper {
    width: calc(100% + 18px);
    padding-bottom: 25px;
  }

  .ss-scroll {
  	border-radius: 0;
  	background: rgba(0, 0, 0, 0.2);
  }
}

.style-switcher.show {
  -webkit-transform: translateX(0);
    	  transform: translateX(0);
}

.style-switcher ul {
  list-style: outside none;
}

/* Style Switcher Icon */
.ss-icon {
  color: #fff;
  background: #222;
  font-size: 25px;
  width: 55px;
  height: 55px;
  line-height: 50px;
  text-align: center;
  position: absolute;
  top: 211px;
  left: -55px;
  border-radius: 10px 0 0 10px;
  box-shadow: -2px 0 7px rgba(0, 0, 0, 0.2);
}

.ss-icon:hover, .ss-icon:focus {
  color: #fff;
}

.ss-icon.rotating * {
    -webkit-animation: ssIconRotate 5s linear infinite;
      -ms-animation: ssIconRotate 5s linear infinite;
        animation: ssIconRotate 5s linear infinite;
}

@-webkit-keyframes ssIconRotate {
    from{
        -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    }
    to{
        -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
    }
}

@-ms-keyframes ssIconRotate {
    from{
        -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    }
    to{
        -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
    }
}

@keyframes ssIconRotate {
    from{
        -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    }
    to{
        -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
    }
}


/* Style Switcher Title */
.style-switcher h3 {
    height: 75px;
    line-height: 75px;
    padding: 0 20px;
    margin: 0;
    font-size: 18px;
    text-align: center;
    word-spacing: 0px;
    font-weight: 400;
    background: #f9f9f9;
    letter-spacing: 0;
}


/* Style Switcher Content */
.style-switcher-content {
  background: #fff;
  // box-shadow: 0 0 15px 10px rgba(0, 0, 0, 0.03);
  /*box-shadow: -3px 0 50px -2px rgba(0, 0, 0, 0.14);*/
  padding: 30px 25px;
  overflow-y: auto;
  height: calc(100% - 50px);
}

.style-switcher-content .ss-intro {
  margin-top: 20px;
}

.style-switcher-content p {
  font-size: 12px;
  line-height: 18px;
}

/* Style Switcher Content Box */
.ss-content-box {
  & + .ss-content-box {
  	margin-top: 30px;
  	padding-top: 30px;
  	border-top: 1px solid #eee;
  }

  > h5 {
    margin-bottom: 15px;
    font-size: 15px;
  }
}

.ss-content-box:last-child {
  margin-bottom: 0;
}

/* Colors Skins */
.colors-skins {
  font-size: 0;
  margin-bottom: -5px; /* reversing "li" margin bottom */
}

.colors-skins li {
  margin: 0 5px 5px 0;
  display: inline-block;
  vertical-align: top;
}

.colors-skins li button {
  position: relative;
  display: block;
  width: 34px;
  height: 34px;
  padding: 0;
  border-radius: 9999px;
  border: none;
  outline: none;
  cursor: pointer;
  box-shadow: none;
  background: #eee;
}

.colors-skins li button:after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 9999px;
  opacity: 0;
  background: rgba(#fff, 0.3);
  transition: all 0.2s;
}

.colors-skins li button.active:after {
  width: 10px;
/*  content: "\f00c";
  font-family: "fontAwesome";
  font-size: 20px;
  color: #fff;
*/  height: 10px;
  top: 50%;
  left: 50%;
  opacity: 1;
  background: rgba(#fff, 0.55);
}

.colors-skins li button:hover:after {
  opacity: 1;
}

/* Default */
.colors-skins li button.default {
  background: $theme-color-1;
}

/* Red */
.colors-skins li button.red {
  background: #f9704f;
}

/* Green */
.colors-skins li button.green {
  background: #1bc768;
}

/* Blue */
.colors-skins li button.blue {
  background: #65a4e4;
}

/* Yellow */
.colors-skins li button.yellow {
  background: #ffc527;
}

/* Orange */
.colors-skins li button.orange {
  background: #f58b3c;
}

/* Brown */
.colors-skins li button.brown {
  background: #da6540;
}

/* Light Green */
.colors-skins li button.light-green {
  background: #1dcca9;
}

/* Lime */
.colors-skins li button.lime {
  background: #86ba41;
}

/* Purple */
.colors-skins li button.purple {
  background: #5753e4;
}

/* Turquoise */
.colors-skins li button.turquoise {
  background: #0dc0c0;
}

/* Switch */
.switch {
  text-align: center;
  font-size: 0;

  & + .switch {
	  margin-top: 5px;
  }
}

.switch:last-child {
  margin-bottom: 0;
}

.switch span {
  font-size: 10px;
  line-height: 13px;
  position: relative;
  vertical-align: middle;
  text-transform: uppercase;
  display: inline-block;
  letter-spacing: 1px;
  width: 38.5%;
  transition: all 0.15s;
}

.switch span:first-child,
.switch span:last-child {
  transition: all 0s;
  cursor: pointer;
}

.switch span:first-child {
  padding-right: 8px;
  text-align: right;
}

.switch span:last-child {
  padding-left: 8px;
  text-align: left;
}

.switch span.active {
  color: #222;
  font-weight: 600;
}

/* Switch Button */
.switch-button {
  width: 45px !important;
  height: 24px;
  border: 1px solid #bbb;
  border-radius: 9999px;
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.switch-button:after {
  content: "";
  position: absolute;
  left: 2px;
  top: 2px;
  width: 18px;
  height: 18px;
  background: #222;
  border-radius: 9999px;
      transition: all 0.15s;
}

.switch-button.active:after {
  left: 23px;
  background: #f17663;
}

/* Demos Preview */
.demos-preview {
  font-size: 0;
}

.demos-preview li {
  vertical-align: top;
  // float: left;
  margin-bottom: 15px;
  // width: 50%;

  &:nth-child(odd) {
    // padding-right: 5px;
  }

  &:nth-child(even) {
    // padding-left: 5px;
  }

  & + li {
    // margin-top: 10px;
  }
}

.demos-preview li a {
  overflow: hidden;
  position: relative;
  display: block;
  max-height: 168px;
  width: calc(100% - 1px);
  border: 1px solid #e5e5e5;
  // padding: 5px;
  font-size: 10px;
  line-height: 13px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1px;
  background: #f1f1f1;
  color: #888;

  &::after,
  h5 {
    position: absolute;
    visibility: hidden;
    opacity: 0;
    transition: all 0.2s;
  }

  &:hover {
    &::after,
    h5 {
      visibility: visible;
      opacity: 1;
    }

    h5 {
      transition-delay: 0.1s;
    }
  }

  &::after {
    top: 0;
    left: 0;
    content: "";
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
  }

  h5 {
    z-index: 1;
    top: 50%;
    left: 50%;
    width: 100%;
    padding: 0 20px;
    text-align: center;
    text-transform: capitalize;
    transform: translate(-50%, -50%);
    font-size: 14px;
    // font-weight: 500;
    color: $theme-color-1;
  }
}

.demos-preview li a:hover {
  color: #222;
  background: #e1e1e1;
}

/* Demos Preview Image */
.dp-img {
  position: absolute;
  left: calc(-100% - 10px);
  bottom: 150px;
  width: 100%;
  max-width: initial;
  border: 5px solid #fff;
  opacity: 0;
  visibility: hidden;
  transform: translateX(30px);
  transition: all 0.2s 0.3s;
  box-shadow: 0 0 15px rgba(0,0,0, 0.1);
  background: #ccc;

  &.appeared {
  opacity: 1;
  visibility: visible;
  transform: translateX(0);
  transition: all 0.2s;
  }
}


/* List Patterns */
.list-patterns {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: row wrap;
  margin-top: 20px;
  width: 100%;
  text-align: center;

  li {
    display: inline-block;
    margin: 0 5px 5px 0;

    button {
      overflow: hidden;
      position: relative;
      display: block;
      width: 45px;
      height: 45px;
      padding: 0;
      border-radius: 9999px;
      border: none;
      outline: none;
      cursor: pointer;
      // border: 1px solid rgba(#000,0.1);
      // box-shadow: 0 0 0 2px #888;

      &.active {
        // border-color: rgba(#000,0.6);

        &::after {
          box-shadow: inset 0 0 0 3px rgba($theme-color-1,0.4);
        }
      }

      &::after {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        border-radius: 9999px;
        box-shadow: inset 0 0 0 1px rgba(#000,0.1);
        transition: all 0.2s;
      }

      img {
        display: block;
      }
    }
  }
}

.colors-skins li button:after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 9999px;
  opacity: 0;
  background: rgba(#fff, 0.3);
  transition: all 0.2s;
}

.colors-skins li button.active:after {
  width: 10px;
/*  content: "\f00c";
  font-family: "fontAwesome";
  font-size: 20px;
  color: #fff;
*/  height: 10px;
  top: 50%;
  left: 50%;
  opacity: 1;
  background: rgba(#fff, 0.55);
}

.colors-skins li button:hover:after {
  opacity: 1;
}





/* Responsive case */
@media (max-height: 720px) {

  .style-switcher {

  }

  .ss-content {

  }

  .demos-preview li img { 
    left: 293px;
  }

}

@media (max-width: 480px) {
  .style-switcher {
    display: none !important;
  }
} // < 480px width


/* Simple Scroll */
.ss-wrapper {
  // overflow: hidden;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
  float: left;

  // Override
  margin-left: -18px;
  width: calc(100% + 0); // Space for scroller related with margin-left.
  overflow-x: hidden;
}

.ss-wrapper > .ss-content {
  height: 100%;
  width: 100%;
  padding: 0 32px 0 0;
  position: relative;
  right: -18px; // -18px
  overflow: auto;
  box-sizing: border-box;

  // Override
  padding: 0;
  overflow-x: hidden;
  overflow-y: scroll;
}

.ss-scroll {
  position: absolute;
  background-color: rgba(0,0,0,0.1);
  width: 9px;
  border-radius: 4px;
  top: 0;
  z-index: 2;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.25s linear;

  // Override
  right: 0 !important;
  // opacity: 0.5;
  width: 6px;
  transition: color 0.25s, opacity 0.25s;
}

.ss-hidden {
  display: none;
}

.ss-container:hover .ss-scroll {
  opacity: 1;
}

.ss-grabbed {
  -o-user-select: none;
  -ms-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

